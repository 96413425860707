<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Realtime Users <span class="text-muted">{{ totalUsers }}</span></div>
    </div>
    <div class="card-body">
      <FadeSlideAnimation
        duration="5"
        leave-first
      >
        <NoOrder
          v-if="!Object.keys(routesWithUsers).length"
          text="No Activities to Show"
          title="There is no active users"
          icon="icon-archive text-warning"
        />
        <div
          :class="`timeline-activity ${isMobile ? '' : 'live-users-container'}`"
          v-else
        >
          <StackedAnimation
            duration="2"
            direction-in="right"
            direction-out="right"
            initial-animation
          >
            <div
              class="activity-log"
              v-for="(users, route) in routesWithUsers"
              :key="route"
            >
              <p class="log-name">
                {{ getRouteName(route) }} <span class="text-muted"> {{ users.length }}</span>
                <a
                  :href="getUILink(route)"
                  class="log-time"
                  target="_blank"
                >
                  {{ route }} <i class="icon-open_in_new" />
                </a>
              </p>


              <div
                class="log-details"
                v-for="user in users"
                :key="user.id"
              >
                <div class="mt-1 d-flex align-items-center">
                  <div class="avatar d-flex align-items-center">
                    <router-link to="/customers">
                      <UserAvatar
                        :name="user.name"
                      />
                    </router-link>
                  </div>
                  <div class="ml-3">
                    <router-link to="/customers">
                      <div class="fs18 customer-name">{{ user.name }}</div>
                    </router-link>
                    <a href="mailto:user@email.address" class="customer-mail">{{ user.email }}</a>
                  </div>
                </div>
              </div>
            </div>
          </StackedAnimation>

        </div>
      </FadeSlideAnimation>
    </div>
  </div>
</template>

<script>
import NoOrder from '@/components/Util/card/noOrder';
import { computed, onMounted, onUnmounted, reactive } from 'vue';
import Echo from '@/Plugins/Echo';
import UserAvatar from '@/components/Util/UserAvatar';
import { useStore } from 'vuex';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'DashboardActivities',
  components: { StackedAnimation, FadeSlideAnimation, UserAvatar, NoOrder },

  setup () {

    const store = useStore();
    const userStore = store.state.user;

    const uiRoutes = [
      { path: '/', name: 'Home' },
      { path: '/order', name: 'Order' },
      { path: '/order/history', name: 'Order History' },
      { path: '/order/confirmation', name: 'Order Confirmation' },
      { path: '/reservation', name: 'Reservation' },
    ];

    const state = reactive({
      users: {
        '/': [],
        '/order': [],
        '/order/history': [],
        '/order/confirmation': [],
        '/reservation': [],
      }
    });

    const listenPaths = computed(() => uiRoutes.map(route => btoa(route.path)));

    // keep routes that has users
    const routesWithUsers = computed(() => {

      const data = {};

      Object.keys(state.users).map(path => {

        const pathUsers = state.users[path];

        if (pathUsers.length) {
          data[path] = pathUsers;
        }
      });

      return data;
    });

    const totalUsers = computed(() => {
      let total = 0;

      Object.values(routesWithUsers.value).map(users => {
        total += users.length;
      });

      return total;
    });

    onMounted(() => {
      activityListener();
    });

    onUnmounted(() => {
      activityListener(true);
    });

    const activityListener = (stopListening = false) => {

      // when need to stop listening
      if (stopListening) {

        listenPaths.value.map(listenPath => {

          const channelName = `activityCh.${listenPath}`;
          Echo.leave(channelName);

        });

        return;
      }

      // when starting listening
      listenPaths.value.map(listenPath => {

        const channelName = `activityCh.${listenPath}`;

        Echo
          .join(channelName)
          .here((users) => {
            mapUsers(users);
          })
          .joining((user) => {
            mapUsers(user);
          })
          .leaving((user) => {
            mapUsers(user, false);
          })
          .error((error) => console.log(`Activity record error on '${listenPath}': ${error.message}`))
        ;

      });

    };

    function mapUsers (users, addUser = true) {

      // map users
      if (Array.isArray(users)) {

        users.map(user => {
          if (state.users[user.path] && (userStore.data.id !== user.id)) state.users[user.path].push(user);
        });

        return;
      }

      // here users contains a single user

      // if path not found leave it
      if (!state.users[users.path]) {
        console.log(`Activity record: invalid path '${users.path}'`);
        return;
      }

      // add user
      if (addUser) {
        state.users[users.path].push(users);
        return;
      }

      // remove user
      state.users[users.path] = state.users[users.path].filter(user => user.id !== users.id);

    }

    const getRouteName = (route) => {
      const routeObj = uiRoutes.find(r => r.path === route);

      return routeObj ? routeObj.name : `Unknown (${route})`;
    };

    const getUILink = (route) => {
      return `${process.env.VUE_APP_BASE_URL_UI}${route}`;
    };

    return {
      state,
      routesWithUsers,
      totalUsers,
      isMobile,
      getRouteName,
      getUILink,
    };
  }
};
</script>

<style scoped>
.live-users-container {
  max-height: 355px;
  overflow: auto;
}
</style>
