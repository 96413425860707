<template>
  <ModalComp
    :title-text="title"
    :show="state.showModal"
    class="mt-3"
    @hide="fireEvent('cancelled')"
    @submit="fireEvent('updated', null)"
    custom-class="updateAction"
    :show-action-button="false"
  >

    <div class="row d-flex align-items-center no-gutters">
      <div class="col-sm-12">
        <ButtonComp
          v-for="(item, idx) in state.optionList"
          :key="idx"
          :icon="state.selectedOption === item ? 'icon-check-circle':'icon-radio_button_unchecked'"
          :button-type="getStatusBtnType(item)"
          :label="getOrderStatusLabel(item)"
          classes="m-2 text-capitalize p-button-lg status-btn"
          @click="state.selectedOption = item"
        />
      </div>

      <FadeSlideAnimation duration="2">
        <div class="col-sm-12 mt-3"
             v-if="state.showDelayOption && !state.isAsap">

          <div class="form-group">
            <div class="label">Delay For</div>
            <div class="delay-button-container">
              <ButtonComp
                v-for="(item, id) in state.delayMinuteOptions"
                :label="item.label"
                :key="`delay-button-${id}`"
                classes="delay-button"
                button-type="info"
                :icon="state.delayMinute === item.id ? 'icon-check-circle':'icon-radio_button_unchecked'"
                @click="handleAddSelectedMinute(item.id)"
              />
              <ButtonComp
                label="Custom"
                classes="delay-button"
                :icon="state.addCustomMinute ? 'icon-check-circle':'icon-radio_button_unchecked'"
                @click="toggleCustomMinutes"
                button-type="info"
              />
              <div
                class="custom-input d-flex flex-nowrap input-group custom-input"
                v-if="state.addCustomMinute"
              >
                <input
                  type="number"
                  class="form-control"
                  v-model="state.delayMinute"
                />
                <span
                  class="input-group-text fs17 bg-primary text-white h-36"
                >
                  Minutes
                </span>
                <button
                  :class="`btn btn-${state.addCustomMinute ? 'danger' : 'primary'} fs-17 h-36`"
                  @click="toggleCustomMinutes"
                >
                  Cancel
                </button>
              </div>
            </div>

            <!--            <div class="d-flex flex-nowrap input-group custom-input">-->
            <!--              <el-select-->
            <!--                v-if="!state.addCustomMinute"-->
            <!--                v-model="state.delayMinute"-->
            <!--                placeholder="will ready after"-->
            <!--                class="w-100 custom-input"-->
            <!--              >-->
            <!--                <el-option-->
            <!--                  v-for="(option, index) in state.addMinuteOptions"-->
            <!--                  :key="`option-${index}`"-->
            <!--                  :label="option.label"-->
            <!--                  :value="option.id"-->
            <!--                />-->
            <!--              </el-select>-->

            <!--              <input-->
            <!--                v-if="state.addCustomMinute"-->
            <!--                type="number"-->
            <!--                class="form-control"-->
            <!--                v-model="state.delayMinute"-->
            <!--              />-->
            <!--              <span-->
            <!--                v-if="state.addCustomMinute"-->
            <!--                class="input-group-text fs17"-->
            <!--              >-->
            <!--                Minutes-->
            <!--              </span>-->
            <!--              <button-->
            <!--                :class="`btn btn-${state.addCustomMinute ? 'danger' : 'primary'} fs-17`"-->
            <!--                @click="toggleCustomMinutes"-->
            <!--              >-->
            <!--                {{ state.addCustomMinute ? 'Cancel' : 'Custom' }}-->
            <!--              </button>-->
            <!--            </div>-->

          </div>
        </div>
      </FadeSlideAnimation>

      <div class="col-sm-12 mt-3" v-if="state.showMailSendOption">
        <div class="label">Notify Customer</div>
        <SwitchComp
          disable-text="Mail will not be sent to customer"
          enable-text="Will send email to customer"
          v-model="state.sendMailToUser"
          :checked="state.sendMailToUser"
          @checked="state.sendMailToUser = $event"
        />
      </div>

      <div class="col-sm-12 mt-4 mb-3">
        <ButtonComp
          button-type="primary"
          label="Submit"
          :loading="state.formSubmitting"
          :disabled="!state.isStatusChanged"
          classes="d-block w-100 p-2 fs18"
          @click="updateData"
        />
      </div>

    </div>

  </ModalComp>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import SwitchComp from '@/components/Util/SwitchComp';
import {
  getOrderStatusNewLabel,
  orderStatuses,
  orderStatusesNew,
  orderStatusesNewRev, orderTypes,
  reservationStatuses
} from '@/utils/Helper';
import { useStore } from 'vuex';
import Toaster from '@/utils/Toaster';
import ButtonComp from '@/components/Util/ButtonComp';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';

export default defineComponent({

  name: 'StatusUpdateAction',
  components: { FadeSlideAnimation, ButtonComp, SwitchComp, ModalComp },
  emits: ['updated', 'cancelled'],
  props: {
    options: {
      type: Array,
    },

    currentItem: {
      type: Object
    },

    showAction: {
      type: Boolean,
      require: true,
    },

    currentOption: {
      type: String
    },

    title: {
      type: String,
      default: 'Update Status'
    },

    actionType: {
      type: String,
      default: 'order'
    }
  },

  setup (props, { emit }) {

    const store = useStore();

    let state = reactive({

      showModal: false,

      optionList: computed(() => validOptions()),
      selectedOption: props.currentOption,

      formSubmitting: false,
      sendMailToUser: true,

      showDelayOptions: [],

      isAsap: props.currentItem.requested_time_is_asap,

      showSendMailOption: false,

      updateType: '',

      showMailSendOption: false,

      addCustomMinute: false,

      delayMinuteOptions: [
        { id: 0, label: 'No Delay' },
        { id: 10, label: '10 minutes' },
        { id: 15, label: '15 minutes' },
      ],

      delayMinute: 0,
      showDelayOption: false,
      isStatusChanged: false,

    });

    const isTypeOrder = computed(() => props.actionType === 'order');

    function autoSelectProcessingIfPending () {
      state.selectedOption = orderStatuses.processing;
    }

    function validOptions () {

      const isCollection = props.currentItem?.type === 'collection';

      if (props.actionType === 'order') {

        let options;

        switch (props.currentOption) {

          case orderStatuses.pending:
            options = [
              orderStatuses.processing,
              orderStatuses.cancelled
            ];
            autoSelectProcessingIfPending()
            break;

          case orderStatuses.processing:
            options = [
              orderStatuses.processing,
              orderStatuses.delivered,
              orderStatuses.cancelled
            ];

            if (!isCollection) {
              options.splice(1, 0, orderStatuses.delivering);
            }
            break;

          case orderStatuses.delivering:
            options = [
              orderStatuses.delivering,
              orderStatuses.delivered, orderStatuses.cancelled
            ];
            break;

          case orderStatuses.delivered:
            options = [orderStatuses.delivered];
            break;

          case orderStatuses.cancelled:
            options = [orderStatuses.cancelled];
            break;

          default:
            options = [];
            break;
        }

        return options;
      }

      if (props.actionType === 'reservation') {
        switch (props.currentOption) {
          case 'pending':
            return ['pending', 'confirmed', 'cancelled'];

          case 'confirmed':
            return ['confirmed'];

          case 'cancelled':
            return ['cancelled'];

          default:
            return [];
        }
      }

      return [];
    }

    const fireEvent = (event, payload = null) => emit(event, payload);

    const handleMailSendOptionView = () => {

      if (!props.currentItem) return;

      let options = [];

      if (props.actionType === 'order') {
        options.push(orderStatuses.pending, orderStatuses.processing, orderStatuses.delivering);

        state.showMailSendOption = options.includes(props.currentItem.status);
        return;
      }

      state.showMailSendOption = props.currentItem.status === orderStatuses.pending;

    };

    const showHideDelayOptions = () => {

      if (!isTypeOrder.value) return false;

      let status = (props.currentOption === orderStatusesNewRev.pending) &&
        (state.selectedOption === orderStatusesNewRev.accepted);

      state.showDelayOption = status;
    };

    const updateData = async () => {

      // dont update if status not changed
      if (!state.isStatusChanged) return;

      state.formSubmitting = true;

      let data = {
        status: state.selectedOption,
        sendEmail: state.sendMailToUser
      };

      if (isTypeOrder.value) {
        data.addMinutes = state.delayMinute;
      }

      try {

        let response = isTypeOrder.value
          ? await store.dispatch('order/updateOrder', { id: props.currentItem.id, data: data })
          : await store.dispatch('reservation/updateReservation', { id: props.currentItem.id, data: data });

        Toaster.successAlt({
          message: response.data.message || 'order status successfully updated'
        });

        fireEvent('updated', response.data.data);

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong'
        });

      }
      state.formSubmitting = false;
    };

    function stopNewOrderSound () {
      if (props.actionType === 'order') {
        store.dispatch('app/stopNewOrderSound');
      }
    }

    const getStatusBtnType = (statusText) => {

      // 'primary', 'secondary', 'success', 'info', 'warning', 'danger'

      switch (statusText) {
        case orderStatuses.pending:
          return 'secondary';

        case orderStatuses.processing:
        case orderStatusesNew.processing:
          return 'primary';

        case orderStatuses.delivering:
        case orderStatusesNew.delivering:
          return 'info';
          
        case reservationStatuses.confirmed:
        case orderStatuses.delivered:
        case orderStatusesNew.collected:
          return 'success';

        case orderStatuses.cancelled:
          return 'danger';

        default:
          return 'secondary';
      }
    };

    const toggleCustomMinutes = () => {
      const updatedStatus = !state.addCustomMinute;

      // when enabling custom minuted populate for 90 or 0
      state.delayMinute = updatedStatus ? 20 : 0;

      state.addCustomMinute = updatedStatus;
    };

    const getOrderStatusLabel = (statusName) => {

      if (!isTypeOrder.value) {
        return statusName;
      }

      const isCollection = props.currentItem?.type === orderTypes.collection;
      return getOrderStatusNewLabel(statusName, isCollection);
    }

    const handleAddSelectedMinute = (value) => {
      state.delayMinute = value;
      state.addCustomMinute = false;
    }

    onMounted(() => {
      state.showModal = props.showAction;
      state.selectedOption = props.currentOption;
      showHideDelayOptions();
      handleMailSendOptionView();
      stopNewOrderSound();
    });

    watch(() => props.showAction, (data) => state.showModal = data);
    watch(() => props.currentOption, (data) => state.selectedOption = data);
    watch(() => props.actionType, (data) => state.updateType = data);

    watch(() => state.selectedOption, (data) => {

      state.isStatusChanged = props.currentOption !== data;

      showHideDelayOptions();
      handleMailSendOptionView();
    });

    return {
      state,
      orderStatusesNew,
      getOrderStatusLabel,
      fireEvent,
      updateData,
      getStatusBtnType,
      toggleCustomMinutes,
      handleAddSelectedMinute
    };
  }
});
</script>

<style lang="scss">
.updateAction {
  .p-dialog-content {
    min-width: 300px;
    padding-top: 10px;
  }
}

.el-input__inner {
  border: 1px solid #607d8b;
  color: #1a1a1a;
}

.status-btn {
  font-size: 30px !important;
  padding: 20px 25px !important;

  .p-button-icon {
    font-size: 30px !important;
  }
}

.delay-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  justify-content: center;

  .custom-input {
    grid-column-end: span 2;
  }

  .delay-button {
    font-size: 25px !important;
    padding: 10px 15px !important;

    .p-button-icon {
      font-size: 25px !important;
    }
  }
}

.custom-input {
  .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 17px;
    padding: 20px 12px;
  }
}
</style>

<style lang="scss" scoped>
.label {
  font-size: 13px;
  font-weight: 600;
  color: grey;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-input {
  input {
    border: 1px solid #607d8b;
    border-right: 0;
    border-radius: 4px;
    font-size: 17px;
  }

  .input-group-text {
    border: 1px solid #607d8b;
    border-left: 0;
    border-radius: 4px;
    background-color: #e9ecef;
  }
}
</style>
