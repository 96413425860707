<template>
  <ModalComp
    :show="modelValue"
    title-icon-class="icon-assignment"
    title-text="Todays Orders"
    header-classes="bg-grey"
    custom-class="w-100 h-100 latest-orders-popup"
    max-width="100%"
    max-height="100%"
    :show-action-button="false"
    @hide="closeModal"
  >
    <section class="popup-container">

      <!--filters-->
      <div class="row order-filters-section">

        <!--status filters-->
        <div class="col-12">
          <div class="row gx-3">
            <div
              v-for="orderStatus in state.filterDefinition.statuses"
              :key="orderStatus"
              class="col"
            >

              <button
                :class="`btn btn-${orderStatus === state.filters.status ? '' : 'outline-'}primary order-filter-btn text-uppercase fw6`"
                @click="setFilterStatus(orderStatus)"
              >
                {{ orderStatus }}
                <div class="order-count-badge">
                  {{ filteredOrdersCount[orderStatus] }}
                </div>
              </button>
            </div>
          </div>
        </div>

        <!--type & payment filters-->
        <div class="col-12 d-flex justify-content-between mt-2">

          <!--order type filter-->
          <div class="btn-group w-100 me-5">
            <button
              v-for="orderType in state.filterDefinition.types"
              :key="orderType"
              :class="`btn btn-rounded btn-${orderType === state.filters.type ? '' : 'outline-'}info fs18 fw6 text-uppercase`"
              @click="setFilterType(orderType)"
            >
              {{ orderType }}
            </button>
          </div>

          <!--payment status filter-->
          <div class="btn-group w-100">
            <button
              v-for="orderPaymentType in state.filterDefinition.paymentTypes"
              :key="orderPaymentType"
              :class="`btn btn-rounded btn-${orderPaymentType === state.filters.paymentType ? '' : 'outline-'}info
                fs18 fw6 text-uppercase
              `"
              @click="setFilterPaymentType(orderPaymentType)"
            >
              {{ orderPaymentType }}
            </button>
          </div>
        </div>
      </div>

      <!--date-->
      <div class="row">
        <div class="col-12">
          <div class="w-100 bg-dark text-white p-2 mt-3 rounded fs25 text-center">
            {{ currentDate }}
          </div>
        </div>
      </div>

      <!--list-->
      <div class="row">
        <div class="order-items-list mb-2">
          <NewOrdersList
            :orders="state.filteredOrders"
          />
        </div>
      </div>
    </section>
  </ModalComp>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import { useStore } from 'vuex';
import NewOrdersList from '@/components/Dashboard/Order/NewOrdersList';
import { orderStatuses, orderStatusesNewRev } from '@/utils/Helper';
import * as dateFns from 'date-fns';

export default {
  name: 'LatestOrdersPopUp',
  components: { NewOrdersList, ModalComp },
  emits: ['update:modelValue'],
  props: {
    modelValue: { // use with v-model for show hide
      type: Boolean
    },
  },

  setup (props, { emit }) {

    const types = {
      statuses: {
        new: 'new',
        accepted: 'accepted',
        all: 'all orders'
      },
      order: {
        all: 'all',
        delivery: 'delivery',
        collection: 'collection'
      },
      orderPayment: {
        all: 'all',
        paid: 'paid',
        due: 'due'
      }
    };

    const store = useStore();

    const state = reactive({
      orders: computed(() => store.getters['dashboard/orders']),
      filteredOrders: [],

      filters: {
        status: types.statuses.all,
        type: types.order.all,
        paymentType: types.orderPayment.all
      },

      filterDefinition: {
        statuses: [
          types.statuses.all,
          types.statuses.new,
          types.statuses.accepted,
        ],
        types: [
          types.order.all,
          types.order.delivery,
          types.order.collection
        ],
        paymentTypes: [
          types.orderPayment.all,
          types.orderPayment.paid,
          types.orderPayment.due
        ]
      },
    });

    const filteredOrdersCount = computed(() => {
      const counts = {
        'all orders': state.orders.length,
        new: state.orders.filter(o => o.status === orderStatuses.pending).length,
        accepted: state.orders.filter(o => o.status === orderStatusesNewRev.accepted).length
      };

      return counts;
    });

    const closeModal = (value) => {

      // update only when modal closes
      if (!value) {
        emit('update:modelValue', value);
      }
    };

    const setFilterStatus = (status) => {
      state.filters.status = status;
    };

    const setFilterType = (type) => {
      state.filters.type = type;
    };

    const setFilterPaymentType = (paymentType) => {
      state.filters.paymentType = paymentType;
    };


    const triggerFilter = () => {

      let filteredData = [...state.orders];

      for (const filter in state.filters) {

        const filterValue = state.filters[filter];

        switch (filter) {

          case 'status':

            if (!filterValue.length) break;

            if (filterValue === types.statuses.all) break;

            filteredData = filteredData.filter(d => {

              const isFilterNew = filterValue === types.statuses.new;
              const isFilterAccepted = filterValue === types.statuses.accepted;

              if (isFilterNew) {
                return d.status === orderStatuses.pending;
              }

              if (isFilterAccepted) {
                return d.status === orderStatusesNewRev.accepted;
              }
            });
            break;

          case 'type': // order type

            if (!filterValue.length) break;

            if (filterValue === types.order.all) break;

            filteredData = filteredData.filter(d => d.type === filterValue);
            break;

          case 'paymentType':

            if (!filterValue.length) break;

            if (filterValue === types.orderPayment.all) break;

            const paymentStatus = filterValue === types.orderPayment.paid ? 1 : 0;

            filteredData = filteredData.filter(d => d.status_payment === paymentStatus);
            break;

          default:
            break;
        }

      }

      state.filteredOrders = filteredData;
    };

    const currentDate = computed(() => {
      const date = new Date();
      const format = 'EEEE, dd LLL yyyy'; //Friday, 28 Feb 2022
      return dateFns.format(date, format);
    });

    watch(() => props.modelValue, (nv) => {
      closeModal(nv);
    });

    // re-apply filter when base orders or filters changes
    watch([() => state.orders, state.filters], (nv) => {
      triggerFilter();
    });

    onMounted(async () => {
      await triggerFilter();
    });

    return {
      state,
      types,
      currentDate,
      filteredOrdersCount,
      closeModal,
      setFilterStatus,
      setFilterType,
      setFilterPaymentType,
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";
.bg-dark {
  background: $sidebar-bg !important;
  color: $title-color !important;
}
.btn-outline-primary {
  background-color: $card-bg;
  border: 1px solid $breadcrumb-item-color !important;
  color: $title-color;

   &:hover,
   &:focus,
   &:active {
     color: $primary !important;
   }
}

.btn-outline-info {
  background-color: $bgFooter;
  color: $title-color;
  border: 1px solid $breadcrumb-item-color;

  &:hover,
  &:focus,
  &:active {
    color: $title-color !important;
  }
}

.popup-container {
  max-height: 100% !important;
  //overflow: hidden;
}

.order-items-list {
  max-height: 100% !important;
  //overflow: scroll;
}

.order-filters-section {

  .btn {
    &:active,
    &:focus,
    &:hover {
      color: $light;
    }
  }
}

.order-filter-btn {
  position: relative;
  font-size: 28px;
  margin: 5px;
  border-radius: 10px;
  padding: 50px 52px;
  width: 100%;


  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .order-count-badge {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    background-color: $black;
    color: $light;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.latest-orders-popup {
  .p-dialog-content {
    height: 100%;
  }
}
</style>
