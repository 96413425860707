<template>
  <div
    v-if="orders.length"
    class="order-item"
  >
    <div class="order-container">
      <div class="order-header fw6">
        <div class="content">Customer</div>
        <div class="content requested-time">Requested For</div>
        <div class="content">Actions</div>
      </div>

      <StackedAnimation
        duration="1"
        stack-duration="0.5"
        direction-in="left"
        direction-out="back"
      >
        <div
          v-for="(order, index) in orders"
          :key="'product'+index"
          :class="`order-contents ${order.id === state.selectedOrder?.id ? 'selected-bg':''}`"
          class="border-4 order-item-bg"
          @click="handleOrderDetailsView(order)"
        >
          <div class="content customer">
            <UserAvatar
              :name="order.customer_name"
              background-color="#616265"
            />
            <div class="customer-info">
              <OrderTimer
                v-if="order.status !== 'delivered' && order.status !== 'cancelled'"
                :date="order.requested_delivery_time"
                :order="order"
              />
              <div class="name fs20 fw6">
                {{ order.customer_name }}
                <i class="icon-phone-call" />
                {{ order.customer_phone }}

                <i
                  v-if="!order.last_email_send_status"
                  class="icon-error"
                  title="Error occurred during mail send"
                />
              </div>
              <div class="phone text-capitalize fs20">
                {{ order.payment_type }}
                <span class="text-muted">|</span>
                {{ renderPaymentStatus(order) }}
              </div>
            </div>
          </div>
          <div class="content requested-time">
            <div class="sold fs20 fw6">
              {{
                order.requested_time_is_asap ? 'ASAP' : formatRequestedForDate(order)
              }}
              <br />
              <span
                :class="`badge badge-${order.type == 'collection' ? 'info' : 'primary'}`"
              >
              {{ order.type }} <span v-if="order.type == 'delivery'">- {{ order.postcode }}</span>
            </span>
            </div>
          </div>
          <div class="content price d-flex flex-row">
            <ButtonComp
              :loading="state.orderPrintInProgress && state.selectedOrder?.id === order.id"
              icon-only
              icon="icon-print"
              classes="fs35 py-0 px-4 btn-printer"
              @click.stop="handleOrderPrint(order)"
            />

            <div class="sold-title">
              <span
                :class="`badge text-uppercase cursor-pointer ${getBadgeClass(order.status)} p-3 fs15`"
                @click.stop="updateOrder(order)"
              >
                {{ getOrderStatusNewLabel(order.status, (order.type === 'collection')) }}
              </span>
            </div>
          </div>
        </div>
      </StackedAnimation>
    </div>

    <OrderActionSimple
      v-model="state.showOrderDetails"
      :order="state.selectedOrder"
    />

    <StatusUpdateAction
      v-if="state.showStatusAction"
      :show-action="state.showStatusAction"
      :options="state.statusOptions"
      :current-option="state.selectedOrder?.status"
      :current-item="state.selectedOrder"
      action-type="order"
      :title="state.statusActionTitle"
      @cancelled="handleStatusUpdateAction(false)"
      @updated="handleStatusUpdateAction"
    />
  </div>

  <div
    v-else
    class="d-flex justify-content-center align-items-center no-data"
  >
    <div>
      <div class="h3">{{ noOrdersTitle }}</div>
      <div class="h5">{{ noOrdersSubTitle }}</div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/Util/UserAvatar';
import { currency, getBadgeClass, dateFormat } from '@/Mixins/appHelper';
import Order from '@/Services/Order';
import Toaster from '@/utils/Toaster';
import { computed, reactive } from 'vue';
import ButtonComp from '@/components/Util/ButtonComp';
import OrderTimer from '@/components/Util/OrderTimer';
import StatusUpdateAction from '@/components/Util/StatusUpdateAction';
import { useStore } from 'vuex';
import { orderStatuses, orderStatusesNew, getOrderStatusNewLabel } from '@/utils/Helper';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';
import OrderActionSimple from '@/components/Order/OrderActionSimple'
import { DateTime } from 'luxon';

export default {
  name: 'NewOrdersList',
  components: { OrderActionSimple, StackedAnimation, StatusUpdateAction, ButtonComp, UserAvatar, OrderTimer },
  props: {
    orders: {
      type: Array,
      required: true
    },
    noOrdersTitle: {
      type: String,
      default: 'No new orders available.'
    },
    noOrdersSubTitle: {
      type: String,
      default: 'Once there\'s new order, will show-up here'
    }
  },

  setup (props, { emit }) {

    const store = useStore();

    const state = reactive({
      orderPrintInProgress: false,

      statusActionTitle: 'Update Order Status',
      orderOriginalStatuses: computed(() => store.getters['dashboard/orderStatuses']),
      statusOptions: [],
      showStatusAction: false,

      showOrderDetails: false,

      selectedOrder: {},
      date: {}
    });

    function handleOrderUpdate () {
      state.statusActionTitle = 'Update Order Status';
      state.showStatusAction = true;
      state.statusOptions = computed(() => {
        const isCollectionOrder = (state.selectedOrder?.type === 'collection');

        return state.orderOriginalStatuses
          .map(status => {
            switch (status) {
              case 'processing':
                return 'accepted';

              case 'delivering':
                return isCollectionOrder ? false : 'on the way';

              case 'delivered':
                return isCollectionOrder ? 'collected' : 'delivered';

              default:
                return status;
            }
          }).filter(status => status);
      });

      // reset new orders
      store.dispatch('app/resetNewOrdersCount');
    }

    const handleStatusUpdateAction = (updatedOrder = false) => {

      if (updatedOrder) {
        emit('orderUpdated', state.selectedOrder?.id);

        incrementOrDecrementOrderCount(state.selectedOrder, updatedOrder);
      }

      state.showStatusAction = !state.showStatusAction;
    };

    function incrementOrDecrementOrderCount (order, updatedOrder) {

      // increment count if status pending to processing
      if ((order.status === orderStatuses.pending) && (updatedOrder.status === orderStatuses.processing)) {
        store.dispatch('dashboard/incrementOrderCount');
      }

      // decrement count if accepted order status changed to cancelled
      if ((order.status !== orderStatuses.pending) && (updatedOrder.status === orderStatuses.cancelled)) {
        store.dispatch('dashboard/decrementOrderCount');
      }
    }

    const renderPaymentStatus = (order) => {
      return currency(order.total);
    };

    const handleOrderPrint = async (order) => {

      // state.orderPrintInProgress = true;

      state.selectedOrder = order;

      try {

        await Order.print(order.id);

        // Toaster.info({
        //   title: 'Printing in processing',
        //   message: 'Printing will start soon'
        // });

      } catch (e) {
        Toaster.error({
          message: 'Something went wrong.',
          timeout: 2000
        });
      }

      // state.orderPrintInProgress = false;
    };

    const updateOrder = (item) => {

      if (!item) return;

      state.selectedOrder = item;

      handleOrderUpdate(item);

    };

    const handleOrderDetailsView = (order) => {
      state.selectedOrder = order;
      state.showOrderDetails = true;
    };

    const isToday = (startDate) => {

      let d1 = DateTime.fromFormat(startDate, 'dd-MM-yyyy');
      let d2 = DateTime.now();

      return d1.month === d2.month && d1.day === d2.day && d1.year === d2.year;

    }


    const formatRequestedForDate = (order) => {
      const deliveryTime = order.requested_delivery_time;

      const format = isToday(deliveryTime) ? 'hh:mm a' : 'dd MMM, hh:mm a';

      return dateFormat(deliveryTime, format);
    };

    return {
      state,
      isToday,
      getOrderStatusNewLabel,
      getBadgeClass,
      formatRequestedForDate,
      orderStatusesNew,
      renderPaymentStatus,
      handleOrderPrint,
      updateOrder,
      handleStatusUpdateAction,
      handleOrderDetailsView,
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";

$listBgColor: $warning;
$hoverBgColor: darken($listBgColor, 7%);
.no-data .h3{
  color: $title-color;
}
.no-data .h5{
  color: $breadcrumb-item-color;
}
.order-header {
  position: sticky;
  top: 0;
  z-index: 1;
  color: $darkColor;
  text-transform: uppercase;

  .content {
    justify-content: left !important;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: $hoverBgColor;
    position: absolute;
    backdrop-filter: blur(3px);
    top: 0;
    left:0;
    z-index: -1;
  }
}

.order-item-bg {
  background-color: $listBgColor;
}

.selected-bg {
  transition: background-color 50ms;
  background-color: $hoverBgColor !important;
}

.order-contents {
  transition: background-color 500ms;
  color: $black;

  .content {
    justify-content: left !important;
    text-align: left !important;
  }

  &:hover {
    @extend .selected-bg;
  }
}

.min-width110 {
  min-width: 110px;
}

</style>
